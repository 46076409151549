import React from 'react';
import './Home.css';
import Hero from './Hero/Hero';
import Face_Swapper from './Face-swipper/Face-swapper';
import Logo_Maker from './Logo-Maker/LogoMaker';
import Image_Maker from './ImageMaker/ImageMaker';
import Video_Maker from './VideoMaker/VideoMaker';
import Speech_Maker from './SpeechMaker/SpeechMaker';
import Design_Maker from './DesignMaker/DesignMaker';
import Ai_Writer from './AIwriter/AiWriter';
import Smarter from './Smarter/Smater';
import Faster from './Faster/Faster';
import Easier from './Easier/Easier';

const Home = () => {
    return(
        <>
            <Hero />
            <Face_Swapper />
            <Logo_Maker />
            <Image_Maker />
            <Video_Maker />
            <Speech_Maker />
            <Design_Maker />
            <Ai_Writer />
            <div className="design-smarter-sec">
            <Smarter />
            <Faster />
            <Easier />
            </div>
        </>
    )
}

export default Home;