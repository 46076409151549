import React from 'react';
import EasierImg from '../../../../assets/images/easier1.webp';
import EasierImg2 from '../../../../assets/images/easier2.webp';

const Easier = () => {
    return(
        <>
        <div className="smarter-sec">
            <div className="container">
                <div className="smarter-inner-wrp">
                <div className="row">
                    <div className="col-md-6">
                        <div className="smarter-cont-wrp">
                        <h5><strong>03.</strong> EASIER</h5>
                        <p>With its intuitive user experience, Designs.ai makes design accessible. You can create stunning visual content even as a beginner.</p>
                        <ul>
                            <li>Step-by-step creation across all tools</li>
                            <li>Fully-licensed assets for commercial use</li>
                            <li>Simple editor to easily customize initial drafts</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="smarter-img-wrp">
                            <img src={EasierImg} alt="Easier" />
                            <img src={EasierImg2} alt="Easier" className="smart-img-2"/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Easier;