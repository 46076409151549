const sub_item = [
    {
        id: 1,
        main_title: "Create",
        items: [
            {
                id: 1,
                title: "AI Draw",
                description: "Turn drawings into visually captivating masterpieces",
                imgSrc: require('../../assets/images/icon_ai_draw.svg').default,
                alt: "Draw AI"
            },
            {
                id: 2,
                title: "Face Swapper",
                description: "Electrify your media with face-swapping magic",
                imgSrc: require('../../assets/images/icon_fs_c1.svg').default,
                alt: "Face Swapper"
            },
            {
                id: 3,
                title: "Imagemaker",
                description: "Electrify your media with face-swapping magic",
                imgSrc: require('../../assets/images/icon_im.svg').default,
                alt: "Imagemaker"
            },
            {
                id: 4,
                title: "AI Writer",
                description: "Elevate your marketing and SEO-friendly content game",
                imgSrc: require('../../assets/images/icon_cw.svg').default,
                alt: "AI Writer"
            },
        ],
    },
    {
        id: 2,
        main_title: "Tool",
        items: [
            {
                id: 1,
                title: "COLORMATCHER",
                description: "Use AI to match colors for the perfect palette",
                imgSrc: require('../../assets/images/icon_cm.svg').default,
                alt: "COLORMATCHER"
            },
            {
                id: 2,
                title: "GRAPHICMAKER",
                description: "Free SVG vectors with in-browser editor",
                imgSrc: require('../../assets/images/icon_gm.svg').default,
                alt: "GRAPHICMAKER"
            },
            {
                id: 3,
                title: "FONT PAIRER",
                description: "Generate stunning font combinations with AI",
                imgSrc: require('../../assets/images/icon_fp.svg').default,
                alt: "FONT PAIRER"
            },
            {
                id: 4,
                title: "CALENDAR",
                description: "A collection of social holidays and events",
                imgSrc: require('../../assets/images/icon_calendar.svg').default,
                alt: "CALENDAR"
            },
        ],
    },
    {
        id: 3,
        main_title: "Resource",
        items: [
            {
                id: 1,
                title: " ",
                description: "Quick simple tutorials to get started",
                imgSrc: require('../../assets/images/icon_tutorial.svg').default,
                alt: "icon_tutorial"
            },
            {
                id: 2,
                title: " ",
                description: "Learn more about AI, design and marketing",
                imgSrc: require('../../assets/images/icon_blog.svg').default,
                alt: "icon_blog"
            },
            {
                id: 3,
                title: " ",
                description: "Help us expand our growing community",
                imgSrc: require('../../assets/images/icon_affiliate.svg').default,
                alt: "icon_affiliate"
            },
            {
                id: 4,
                title: "",
                description: "Scale your creative production with AI",
                imgSrc: require('../../assets/images/icon_agencies.svg').default,
                alt: "icon_agencies"
            },
        ],
    },
    {
        id: 4,
        main_title: "Pricing",
        items: [],
    },
];

export default sub_item;