import React from 'react';
import FasterImg from '../../../../assets/images/faster1.webp';
import FasterImg2 from '../../../../assets/images/faster2.webp';

const Faster = () => {
    return(
        <>
        <div className="smarter-sec">
            <div className="container">
                <div className="smarter-inner-wrp">
                <div className="row">
                <div className="col-md-6">
                        <div className="smarter-img-wrp">
                            <img src={FasterImg} alt="Faster" />
                            <img src={FasterImg2} alt="Faster" className="smart-img-2"/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="smarter-cont-wrp">
                        <h5><strong>02.</strong> FASTER</h5>
                        <p>Create full marketing campaigns in only 2 minutes with artificial intelligence. Quicker creation time, more clients and revenue.</p>
                        <ul>
                            <li>Reduce manual design work by 70%-80%</li>
                            <li>Resize for all online and offline platforms</li>
                            <li>Keep all your creative assets in one place</li>
                        </ul>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Faster;