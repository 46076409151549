import React, {useState, useEffect, useRef} from 'react';
import {gsap} from 'gsap';

import Logo from '../../assets/images/logo.webp';
import './Header.css';
import sub_item from './Data';

const Header = () => {
    const headerLogo = useRef();
    const menuItem = useRef();
    useEffect(() => {
        const logo_Img = headerLogo.current;
        gsap.fromTo(logo_Img, {x:-500, scale:0}, {
            x:0,
            scale:1.1,
            duration:2
        })

        const menu_Item = menuItem.current;
        gsap.fromTo(menu_Item, {y:-200}, {
            y:0,
            duration:1
        })
    });
    const [activeId, setActiveId] = useState(null);
    const handleDropdownClick = (event, id) => {
        event.preventDefault();
        setActiveId(id === activeId ? null : id);
        
    };

    return(
        <>
            <div className='header-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-2'>
                            <div className='logo-wrp'>
                                <img src={Logo} alt="Logo"  ref={headerLogo}/>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="navbar-wrp" >
                            <ul>
                            {sub_item.map((category) => (
                <li
                    key={category.id}
                    onClick={(event) => handleDropdownClick(event, category.id)}
                    className={activeId === category.id ? 'active-item' : ''} 
                >
                    <a href="#">{category.main_title} {category.items.length > 0 && ( <span><i class="fa-solid fa-angle-down"></i></span>)}</a>
                    {category.items.length > 0 && (
                        <ul className={`sub-menu ${activeId === category.id ? 'active' : ''}`} >
                            {category.items.map((item) => (
                                <li key={item.id}>
                                    <a href="#">
                                        <span className="sub-item-img-wrp">
                                            <img src={item.imgSrc} alt={item.alt} />
                                        </span>
                                        <span>
                                            {item.title !== '' && <p>{item.title}</p>}
                                            {item.description !== '' && <p>{item.description}</p>}
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="language-wrp">
                            <ul >
                                <li>
                                    <a href="#">EN</a>
                                </li>
                                <li>
                                    <a href="#">Sign In</a>
                                </li>
                            </ul>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;