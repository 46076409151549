import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger';
import DesignMaker from '../../../../assets/images/designmaker.webp';
const FaceSwapper = () => {
    const designImg = useRef();
    const designContent = useRef();
    useEffect(() => {
        const designimg = designImg.current;
        gsap.fromTo(designimg, {opacity:0, scale:0}, {
            opacity:1,
            scale:1,
            duration:1,
            scrollTrigger:{
                trigger:designimg,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })

        const designcont = designContent.current;
        gsap.fromTo(designcont, {opacity:0, scale:0}, {
            opacity:1,
            scale:1,
            duration:1,
            scrollTrigger:{
                trigger:designimg,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })
    })
    return(
        <>
        <div className="face-swapper-sec">
            <div className="container">
                 
                <div className="faceswapper-wrpper">
                <div className="row">
                <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={DesignMaker} alt="DesignMaker" ref={designImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp designmaker-cont-wrp" ref={designContent}>
                            <h4><strong>DESIGN</strong>MAKER</h4>
                            <p>Simply input your text, select a dynamic design template, and use the design wizard to generate thousands of potential variations.</p>
                            <ul>
                                <li>
                                    <p>20,000+ stunning templates to choose from</p>
                                </li>
                                <li>
                                    <p>Generate all your marketing collateral in seconds</p>
                                </li>
                                <li>
                                    <p>The design wizard auto-generates and resizes</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Designmaker</a>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;