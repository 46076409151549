import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger';
import AiWriter from '../../../../assets/images/AiWriter.webp';
const FaceSwapper = () => {
    const aiWritter = useRef();
    const aiWritterCont = useRef();
    useEffect(() => {
        const aiwritter = aiWritter.current;
        gsap.fromTo(aiwritter, {rotate:0, scale:0}, {
            rotate:360,
            scale:1,
            duration:1,
            scrollTrigger:{
                trigger:aiwritter,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })
        const aiwrittercont = aiWritterCont.current;
        gsap.fromTo(aiwrittercont, {x:-1000, scale:0}, {
            x:0,
            scale:1,
            duration:1,
            scrollTrigger:{
                trigger:aiwrittercont,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })
    })
    return(
        <>
        <div className="face-swapper-sec">
            <div className="container">
                <div className="faceswapper-wrpper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp aimaker-cont-wrp" ref={aiWritterCont}>
                            <h4><strong>AI</strong>WRITER</h4>
                            <p>Create powerful, compelling marketing copy using A.I. to boost engagement and sales - all at a fraction of the required time, cost, and effort.</p>
                            <ul>
                                <li>
                                    <p>Create a range of marketing materials using basic points</p>
                                </li>
                                <li>
                                    <p>Flexible, engaging yet natural sounding story-telling</p>
                                </li>
                                <li>
                                    <p>Create accurate text for your communication needs</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about AI Writer</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={AiWriter} alt="Ai Writer" ref={aiWritter}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;