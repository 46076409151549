import React, { useEffect, useRef } from 'react';
import faceSwapper from '../../../../assets/images/faceswapper.webp';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const FaceSwapper = () => {
    const bannerTitle = useRef();
    const bannerSubTitle = useRef();
    const faceSwapperContent = useRef();
    const faceSwapperImg = useRef();
    useEffect(()=>{
        const banner_Title = bannerTitle.current;
        gsap.fromTo(banner_Title, { scale: 0 }, {
            scale: 1.01, 
            delay:0.5,
            duration: 1, 
            scrollTrigger: {
                trigger: banner_Title,
                start: "top 80%",  // Adjust the start position as needed
                end: "bottom 20%",
                toggleActions: "play none none reverse"
            }
        })
        
        const banner_SubTitle = bannerSubTitle.current;
        gsap.fromTo(banner_SubTitle, { opacity: 0 }, {
            opacity: 1, 
            delay:1,
            duration: 1, 
            scrollTrigger: {
                trigger: banner_SubTitle,
                start: "top 80%",  // Adjust the start position as needed
                end: "bottom 20%",
                toggleActions: "play none none reverse"
            }
        })


        const face_swapper_content = faceSwapperContent.current;
        gsap.fromTo(face_swapper_content, { x: -1000 }, {
            x: 0, 
            delay:1,
            duration: 1, 
            scrollTrigger: {
                trigger: face_swapper_content,
                start: "top 80%",  // Adjust the start position as needed
                end: "bottom 40%",
                toggleActions: "play none none reverse"
            }
        })

        const face_Swapper_img = faceSwapperImg.current;
        gsap.fromTo(face_Swapper_img, { x: 1000}, {
            x:0,
            delay:1,
            duration:1,
            scrollTrigger:{
                trigger:face_Swapper_img,
                start: "top 80%",
                end: "bottom 40%",
                toggleActions: "play none none reverse"
            }
        });
    }, [])
    return(
        <>
        <div className="face-swapper-sec" >
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="faceswapper-inner-wrp" >
                            <h2 ref={bannerTitle}>All the creative tools you need in one platform</h2>
                            <p ref={bannerSubTitle}>Designs.ai is an integrated Agency-as-a-Service platform that uses A.I. technology to allow users to create, edit, and scale content.</p>
                        </div>
                    </div>
                </div>
                <div className="faceswapper-wrpper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp" ref={faceSwapperContent}>
                            <h4><strong>FACE</strong> SWAPPER</h4>
                            <p>Our cutting-edge AI-powered tool seamlessly integrates your face into any image or video.</p>
                            <ul>
                                <li>
                                    <p>Create pictures for Facebook or Instagram, LinkedIn, and creative gaming avatars.</p>
                                </li>
                                <li>
                                    <p>Maps facial features from a photo then blends them onto a target face in a video or photo of your choice.</p>
                                </li>
                                <li>
                                    <p>No images are stored after your face-swapping session is complete.</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Face Swapper</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={faceSwapper} alt="faceSwapper" ref={faceSwapperImg}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;