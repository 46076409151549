import React, {useEffect, useRef} from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger';
import logoMaker from '../../../../assets/images/logomaker.webp';
const FaceSwapper = () => {
    const logoMakerImg = useRef();
    const logoMakerContent = useRef();

    useEffect(() => {
    const logo_Maker_Img = logoMakerImg.current;
    gsap.fromTo(logo_Maker_Img,  {scale:0, opacity:0,},
        {
            scale:1,
            opacity:1,
            delay:1,
            duration:1,
            scrollTrigger:{
                trigger:logo_Maker_Img,
                start:"top 80%",
                end: "bottom 40%",
                toggleActions:"play none none reverse"
            }
        })

        const logo_Maker_Content = logoMakerContent.current;
        gsap.fromTo(logo_Maker_Content,  {scale:0, opacity:0,}, {
            scale:1,
            opacity:1,
            duration:1,
            scrollTrigger:{
                trigger:logo_Maker_Content,
                start: "top 80%",
                end: "bottom 40%",
                toggleActions:"play none none reverse"
            }
        })

}, [])
    return(
        <>
        <div className="face-swapper-sec">
            <div className="container">
                <div className="faceswapper-wrpper">
                <div className="row">
                <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={logoMaker} alt="Logomaker" ref={logoMakerImg}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp logomaker-cont-wrp" ref={logoMakerContent}>
                            <h4><strong>LOGO</strong>MAKER</h4>
                            <p>Use our A.I. logo generator to launch your brand with a unique logo and a full brand identity kit. Choose from over 10,000+ icons.</p>
                            <ul>
                                <li>
                                    <p>Export your logo in JPEG, PDF, PNG and SVG</p>
                                </li>
                                <li>
                                    <p>Kit includes style guidelines and brand narrative</p>
                                </li>
                                <li>
                                    <p>Apply your brand’s style across all your projects</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Face Logomaker</a>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;