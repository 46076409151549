import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger';
import SpeechMaker from '../../../../assets/images/speechmaker.webp';
const FaceSwapper = () => {
    const speechMakerCont = useRef();
    const speechMakerImg = useRef();

    useEffect(() => {
        const speechmakercont = speechMakerCont.current;
        gsap.fromTo(speechmakercont, { x: -1000}, {
            x:0,
            opacity:1,
            duration:1,
            scrollTrigger:{
                trigger:speechmakercont,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse",
            }
        })

        const speechmakerimg = speechMakerImg.current;
        gsap.fromTo(speechmakerimg, { x: 1000}, {
            opacity:1,
            x:0,
            duration:1,
            scrollTrigger:{
                trigger:speechmakerimg,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })
    }) 
    return(
        <>
        <div className="face-swapper-sec">
            <div className="container">
                <div className="faceswapper-wrpper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp speechmaker-cont-wrp" ref={speechMakerCont}>
                            <h4><strong>SPEECH</strong>MAKER</h4>
                            <p>Our text-to-speech technology allows you to convert text scripts into realistic voiceovers with multiple languages, tones and pitches.</p>
                            <ul>
                                <li>
                                    <p>50+ high-quality natural-sounding voiceovers</p>
                                </li>
                                <li>
                                    <p>Generate a voiceover in 15+ global languages</p>
                                </li>
                                <li>
                                    <p>Use for sales videos, explainers, tutorials and more</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Speechmaker</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={SpeechMaker} alt="SpeechMaker" ref={speechMakerImg}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;