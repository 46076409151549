import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger'; 
import Smarterimg from '../../../../assets/images/smarter1.webp';
import Smarterimg2 from '../../../../assets/images/smarter2.webp';

const Smarter = () => {
    const smarterTitle = useRef();
    const smarterSubTitle = useRef();
    useEffect(() => {
        const smartertitle = smarterTitle.current;
        gsap.fromTo(smartertitle, {opacity:0, y:100}, {
            opacity:1,
            y:0,
            duration:1,
            scrollTrigger:{
                trigger:smartertitle,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }

        })

        const smartsubtitle = smarterSubTitle.current;
        gsap.fromTo(smartsubtitle, {x:-1000}, {
            x:0,
            duration:2,
            stagger:5,
            scrollTrigger:{
                trigger:smartsubtitle,
                start:"top 80%",
                end:"bottom 10%",
                toggleActions:"play none none reverse"
            }
        })
    })
    return(
        <>
        <div className="smarter-sec">
            <div className="container">
                <div className="main-title">
                <div className="row">
                    <div className="col-md-12">
                        <div className="smarter-title-wrp">
                            <h2 ref={smarterTitle}>Designs.ai is smarter, faster, and easier</h2>
                        </div>
                    </div>
                </div>
                </div>
                <div className="smarter-inner-wrp">
                <div className="row">
                    <div className="col-md-6">
                        <div className="smarter-cont-wrp">
                        <h5 ref={smarterSubTitle}><strong>01.</strong> SMARTER</h5>
                        <p ref={smarterSubTitle}>Designs.ai uses machine learning to simplify your creative workflow. Less manual work, more time to focus on the bigger picture.</p>
                        <ul>
                            <li>Auto-generates thousands of project variations</li>
                            <li>Analyzes your data to suggest the best content</li>
                            <li>Includes auto-resizing and content replacement</li>
                        </ul>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="smarter-img-wrp">
                            <img src={Smarterimg} alt="Smarter" />
                            <img src={Smarterimg2} alt="Smarter" class="smart-img-2" />
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Smarter;