import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import imageMaker from '../../../../assets/images/imagemaker.webp';
import { ScrollTrigger } from "gsap/ScrollTrigger";

const FaceSwapper = () => {
    const imageMakerSec = useRef();
    const imageMakerImg = useRef();

    useEffect(() => {
        const image_Maker_Content = imageMakerSec.current;
        gsap.fromTo(image_Maker_Content, {x: -1000}, {
            x: 0,
            duration:1,
            scrollTrigger:{
                trigger:image_Maker_Content,
                start: "top 80%",
                end: "bottom 40%",
                toggleActions: "play none none reverse"
            }
        })
   
        const image_maker_img = imageMakerImg.current;
        gsap.fromTo(image_maker_img, { x:1000 }, {
            x:0,
            duration:1,
            scrollTrigger:{
                trigger:image_maker_img,
                start:"top 60%",
                end: "bottom 40%",
                toggleActions:"play none none reverse"
            }
        })

    }, [])
    return(
        <>
        <div className="face-swapper-sec image-maker-sec">
            <div className="container">
                <div className="faceswapper-wrpper">
                <div className="row">
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp imagemaker-cont-wrp" ref={imageMakerSec}>
                            <h4><strong>IMAGE</strong>MAKER</h4>
                            <p>Use our text-to-image generator to effortlessly craft custom-made images from your creative prompts and ideas</p>
                            <ul>
                                <li>
                                    <p>Generate images with a variety of styles like fantasy, neon-punk, and more</p>
                                </li>
                                <li>
                                    <p>Download high-resolution images suitable for professional use</p>
                                </li>
                                <li>
                                    <p>Select from a wide-range of presets to help you generate your idea visual</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Imagemaker</a>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={imageMaker} alt="Imagemaker" ref={imageMakerImg}/>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;