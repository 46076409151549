import React from 'react';
import Logo from '../../assets/images/logo.webp';
import './Footer.css';
const Footer = () => {
    return(
        <>
        <div className="footer-sec">
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <div className="footer-left-wrp">
                            <p className="footer-logo"><img src={Logo} alt="Logo" /></p>
                            <p className="footer-description">Designs.ai is part of a creative ecosystem on a mission to make design easy for everyone.</p>
                            <ul className="social-media-wrp">
                                <li>
                                    <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fa-brands fa-x-twitter"></i></a>
                                </li>
                                <li>
                                    <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                     
                    <div className="col-md-8">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="footer-menu-wrp">
                                    <h5>Company</h5>
                                    <ul>
                                        <li>
                                            <a href="#">About Us</a>
                                        </li>
                                        <li>
                                            <a href="#">Cookie Policy</a>
                                        </li>
                                        <li>
                                            <a href="#">License Agreement</a>
                                        </li>
                                        <li>
                                            <a href="#">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href="#">Terms of Use</a>
                                        </li>
                                        <li>
                                            <a href="#">Contact Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="footer-menu-wrp">
                                    <h5>Products</h5>
                                    <ul>
                                        <li>
                                            <a href="#">AI Draw</a>
                                        </li>
                                        <li>
                                            <a href="#">Face Swapper</a>
                                        </li>
                                        <li>
                                            <a href="#">Imagemaker</a>
                                        </li>
                                        <li>
                                            <a href="#">AI Writer</a>
                                        </li>
                                        <li>
                                            <a href="#">Videomaker</a>
                                        </li>
                                        <li>
                                            <a href="#">Logomaker</a>
                                        </li>
                                        <li>
                                            <a href="#">Image To Text</a>
                                        </li>
                                        <li>
                                            <a href="#">Designmaker</a>
                                        </li>
                                        <li>
                                            <a href="#">Speechmaker</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="footer-menu-wrp">
                                    <h5>Assistive Tools</h5>
                                    <ul>
                                        <li>
                                            <a href="#">Font Pairer</a>
                                        </li>
                                        <li>
                                            <a href="#">Graphicmaker</a>
                                        </li>
                                        <li>
                                            <a href="#">Color Matcher</a>
                                        </li>
                                        <li>
                                            <a href="#">Calendar</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="col-md-3">
                                <div className="footer-menu-wrp">
                                    <h5>Resources</h5>
                                    <ul>
                                        <li>
                                            <a href="#">Blog</a>
                                        </li>
                                        <li>
                                            <a href="#">Inspire</a>
                                        </li>
                                        <li>
                                            <a href="#">Design Types</a>
                                        </li>
                                        <li>
                                            <a href="#">Do's and Don'ts</a>
                                        </li>
                                        <li>
                                            <a href="#">Faq</a>
                                        </li>
                                        <li>
                                            <a href="#">For Agencies</a>
                                        </li>
                                        <li>
                                            <a href="#">Affiliate</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
        </>
    )
}

export default Footer;