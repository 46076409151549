import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { scrollTrigger } from 'gsap/ScrollTrigger';
import VideoMaker from '../../../../assets/images/videomaker.webp';
const FaceSwapper = () => {
    const videoImage = useRef();
    const videoContent = useRef();
    useEffect(() => {
        const video_Image = videoImage.current;
        gsap.fromTo(video_Image, {scale:0, opacity:0,}, {
            scale:1,
            opacity:1,
            duration:1,
            scrollTrigger:{
                trigger:video_Image,
                start:"top 70%",
                end:"bottom 40%",
                toggleActions:"play none none reverse"
            }
        })

        const video_content = videoContent.current;
        gsap.fromTo(video_content, {scale:0, opacity:0,}, {
            scale:1,
            opacity:1,
            duration:1,
            scrollTrigger:{
            trigger:video_content,
            start:"top 70%",
            end:"bottom 40%",
            toggleActions:"play none none reverse"
        }
        })
    })
    return(
        <>
        <div className="face-swapper-sec">
            <div className="container">
                 
                <div className="faceswapper-wrpper">
                <div className="row">
                <div className="col-md-6">
                        <div className="faceswapper-img-wrp">
                            <img src={VideoMaker} alt="Videomaker" ref={videoImage}/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faceswapper-cont-wrp videomaker-cont-wrp" ref={videoContent}>
                            <h4><strong>VIDEO</strong>MAKER</h4>
                            <p>Use our text-to-video technology to transform articles, posts, and text scripts into powerful, fully-edited videos in more than 20 languages.</p>
                            <ul>
                                <li>
                                    <p>10M clips, 170M images, 500K audio files, 50 voices</p>
                                </li>
                                <li>
                                    <p>Resize for Facebook, Instagram, Twitter, TikTok, etc.</p>
                                </li>
                                <li>
                                    <p>Stunning effects, transitions, animations and icons</p>
                                </li>
                            </ul>
                            <a href="#" class="secondary-btn">Learn more about Videomaker</a>
                        </div>
                    </div>
                    
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default FaceSwapper;