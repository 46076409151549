import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
const boxs = [
    {
        imgSrc:require('../../../../assets/images/copywriter_brandkit.webp'),
        buttonTitle:"Text",
        imgAlt:"copywriter_brandkit"
    },
    {
        imgSrc:require('../../../../assets/images/logomaker_brandkit.webp'),
        buttonTitle:"Logo",
        imgAlt:"logomaker_brandkit"
    },
    {
        imgSrc:require('../../../../assets/images/videomaker_video_ad.webp'),
        buttonTitle:"Video",
        imgAlt:"videomaker_video_ad"
    },
    {
        imgSrc:require('../../../../assets/images/imagemaker_hero_screen.webp'),
        buttonTitle:"Image",
        imgAlt:"imagemaker_hero_screen"
    },
    {
        imgSrc:require('../../../../assets/images/designmaker_banner_design.webp'),
        buttonTitle:"Social media",
        imgAlt:"designmaker_banner_design"
    },
    {
        imgSrc:require('../../../../assets/images/speechmaker_voice_recording.webp'),
        buttonTitle:"Voiceover",
        imgAlt:"speechmaker_voice_recording"
    } 
]

const Hero = () => {

    //Gsap Start
    const ref = useRef();
    const bannerSubTitle = useRef();
    const bannerRefs = useRef([]);
    bannerRefs.current = [];

    const addToRefs = (el) => {
      if (el && !bannerRefs.current.includes(el)) {
        bannerRefs.current.push(el);
      }
    };
    useEffect(() => {
        const el = ref.current;
        gsap.fromTo(el, { scale: 0 }, {
            scale: 1.4, 
            duration: 1, 
            scrollTrigger: {
                trigger: el
            }
        })

       
        const banner_SubTitle = bannerSubTitle.current;
        gsap.fromTo(banner_SubTitle, { opacity: 0 }, {
            opacity: 1, 
            delay:2,
            duration: 1, 
            scrollTrigger: {
                trigger: banner_SubTitle
            }
        })

        
        if (bannerRefs.current.length > 0) {
            gsap.fromTo(bannerRefs.current,
                { scale: 0 },
                {
                scale: 1.01,
                duration: 1,
                delay:1,
                 
            }
            );
        }
    }, [])
    //Gsap End

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [hoveredIndexBox, setHoveredIndexBox] = useState(null);

    const handleMouseOver = (index) => {
        setHoveredIndex(index);
        let backgroundColor;
        switch (index) {
            case 0:
              backgroundColor = 'linear-gradient(270deg, #88e5e1, #abe8eb)';
              break;
            case 1:
              backgroundColor = 'linear-gradient(270deg, #88e5e1, #abe8eb)';
              break;
            default:
              backgroundColor = 'linear-gradient(270deg, #d5c9f9, #f0ecff)';
          }
          setHoveredIndexBox(backgroundColor);

    }
 
    const handleMouseLeave = () => {
        setHoveredIndex(null);
        setHoveredIndexBox(null);
    }
     
   
    const getBgColor = (index) => {
        switch(index){
            case 0:
                return '#2572ce';
                break;
            case 1:
              return '#dd2f72';
              break;
            case 2:
                return '#2bbfb8';
                break;
            case 3:
                return '#ffc40c';
                break;
            case 4:
                return '#ff841f';
                break;
            case 5:
                return '#8e47bb';
                break;
            default:
                return '#000';
        }
    } 
    
    return(
        <>
<div className="hero-sec" style={{ backgroundColor: hoveredIndexBox }} id={hoveredIndexBox}>
                <div className="curve-shape"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="hero-inner-wrp">
                                <h1 ref={ref} >Create logos, videos, banners, mockups with A.I. in <br/>2 minutes</h1>
                                <p ref={bannerSubTitle}>What would you like to create with Designs.ai?</p>
                            </div>
                            <div className="box-wrp">
                                <ul>
                                    {boxs.map((box_item, index) => (
                                        <li key={index} ref={addToRefs}>
                                        <a href="#" onMouseEnter={() => handleMouseOver(index)} onMouseLeave={handleMouseLeave}>
                                            <p><img src={box_item.imgSrc} alt={box_item.imgAlt} /></p>
                                            <p className='primary-btn' style={{ backgroundColor: hoveredIndex === index ? getBgColor(index) : '' }}>
                                            {box_item.buttonTitle} <span><i className="fa-solid fa-chevron-right"></i></span>
                                        </p>
                                        </a>
                                      </li>
                                    ))}
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Hero;